  <footer class="page-footer">
    <div class="footer-content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-sm-6">
            <p class="mb-0 text-muted">Developed by <a href="https://marcosdavalos.me" target="_blank">Marcos Dávalos</a></p>
          </div>
          
          <div class="col-sm-6 d-none d-sm-block">
            <ul class="breadcrumb pt-0 pr-0 float-right">
              <li class="breadcrumb-item mb-0">
                <a href="https://play.google.com/store/apps/details?id=com.appdevocionmatutina.devocionmatutina&hl=es_CL" target="_blank" class="btn-link">Play Store</a>
              </li>
              <li class="breadcrumb-item mb-0">
                <a href="https://apps.apple.com/us/app/devocion-matutina/id1381186382" target="_blank" class="btn-link">App Store</a>
              </li>
              <li class="breadcrumb-item mb-0">
                <a href="https://appgallery5.huawei.com/#/app/C102348399" target="_blank" class="btn-link">App Gallery</a>
              </li>
            </ul>
          </div>
          
        </div>
      </div>
    </div>
  </footer>
