// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://api.coloredstrategies.com',
  defaultMenuType: 'menu-default',
  subHiddenBreakpoint: 1440,
  menuHiddenBreakpoint: 768,
  themeColorStorageKey: 'vien-themecolor',
  isMultiColorActive: true,
  /*
  Color Options:
  'light.blueyale', 'light.blueolympic', 'light.bluenavy', 'light.greenmoss', 'light.greenlime', 'light.yellowgranola', 'light.greysteel', 'light.orangecarrot', 'light.redruby', 'light.purplemonster'
  'dark.blueyale', 'dark.blueolympic', 'dark.bluenavy', 'dark.greenmoss', 'dark.greenlime', 'dark.yellowgranola', 'dark.greysteel', 'dark.orangecarrot', 'dark.redruby', 'dark.purplemonster'
  */
  defaultColor: 'light.blueyale',
  isDarkSwitchActive: true,
  defaultDirection: 'ltr',
  themeRadiusStorageKey: 'vien-themeradius',
  isAuthGuardActive: true,

  appdevocionmatutina:{
    apiUrl: 'http://api.appdevocionmatutina.com/v5/',
  },
  
  firebase: {
    apiKey: "AIzaSyBr-elWpSUc6dp9SYEqM08Zr4rAGnra6oQ",
    authDomain: "devocion-matutina-5b1c2.firebaseapp.com",
    databaseURL: "https://devocion-matutina-5b1c2.firebaseio.com",
    projectId: "devocion-matutina-5b1c2",
    storageBucket: "devocion-matutina-5b1c2.appspot.com",
    messagingSenderId: "1076504491653",
    appId: "1:1076504491653:web:c288d0548729665a",
    measurementId: "G-PKSSRSSBGP"
  }
};
