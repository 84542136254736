export interface IMenuItem {
  id?: string;
  icon?: string;
  label: string;
  to: string;
  newWindow?: boolean;
  subs?: IMenuItem[];
}

const data: IMenuItem[] = [
  {
    id: 'home',
    icon: 'iconsminds-bucket',
    label: 'menu.home',
    to: '/app/home'
  },
  {
    id: 'chapters',
    icon: 'iconsminds-bucket',
    label: 'menu.chapters',
    to: '/app/chapters',
    subs: [
      {
        icon: 'simple-icon-paper-plane',
        label: 'menu.home',
        to: '/app/chapters/home'
      },
      {
        icon: 'simple-icon-paper-plane',
        label: 'menu.create',
        to: '/app/chapters/create'
      },
      {
        icon: 'simple-icon-paper-plane',
        label: 'menu.import',
        to: '/app/chapters/import'
      }
    ]
  },
  {
    id: 'authors',
    icon: 'iconsminds-bucket',
    label: 'menu.authors',
    to: '/app/authors',
    subs: [
      {
        icon: 'simple-icon-paper-plane',
        label: 'menu.home',
        to: '/app/authors/home'
      },
      {
        icon: 'simple-icon-paper-plane',
        label: 'menu.create',
        to: '/app/authors/create'
      }
    ]
  },
  {
    id: 'books',
    icon: 'iconsminds-bucket',
    label: 'menu.books',
    to: '/app/books',
    subs: [
      {
        icon: 'simple-icon-paper-plane',
        label: 'menu.home',
        to: '/app/books/home'
      },
      {
        icon: 'simple-icon-paper-plane',
        label: 'menu.create',
        to: '/app/books/create'
      }
    ]
  },
  {
    id: 'categories',
    icon: 'iconsminds-bucket',
    label: 'menu.categories',
    to: '/app/categories',
    subs: [
      {
        icon: 'simple-icon-paper-plane',
        label: 'menu.home',
        to: '/app/categories/home'
      },
      {
        icon: 'simple-icon-paper-plane',
        label: 'menu.create',
        to: '/app/categories/create'
      }
    ]
  },
  {
    id: 'languages',
    icon: 'iconsminds-bucket',
    label: 'menu.languages',
    to: '/app/languages',
    subs: [
      {
        icon: 'simple-icon-paper-plane',
        label: 'menu.home',
        to: '/app/languages/home'
      },
      {
        icon: 'simple-icon-paper-plane',
        label: 'menu.create',
        to: '/app/languages/create'
      }
    ]
  },
  
];
export default data;
